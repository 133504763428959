import { createAction, handleActions } from 'redux-actions';
import moment from 'moment'
import { alphaSortObjectArray } from 'utilities/arrayUtils';
import memoizeOne from 'memoize-one';
import download from 'downloadjs';

//--- [1] Initial State ---
const initialState = {
  //--- SITES ---
  sites: [],
  siteLoading: false,
  siteError: null,

  //--- BUILDINGS ---
  buildings: [],
  buildingLoading: false,
  buildingError: null,

  //--- EQUIP CLASSES ---
  equipClasses: [],
  equipClassLoading: false,
  equipClassError: null,

  //--- EQUIPMENT ---
  equipment: [],
  equipmentLoading: false,
  equipmentError: null,

  //--- POINT CLASSES ---
  pointClasses: [],
  pointClassLoading: false,
  pointClassError: null,

  //--- POINTS ---
  points: [],
  pointLoading: false,
  pointError: null,
  pointCount: 0,
  pointCountError: null,
  pointUnits: null,

  loadingFromURL: false,

  //--- TREND DATA ---
  trendData: [],
  trendDataLoading: false,
  trendDataPointIdsWithData: new Set(),
  contextData: [],
  contextDataLoading: false,
  csvDownload: null,
  csvDownloadLoading: false,
  csvDownloadError: null,
  trendDataError: null,
  contextDataError: null,

  //--- INPUTS ---
  savedInputs: [],
  savedInputsLoading: false,
  savedInputsError: null,

  //--Library--

  libraryData: [],
  libraryDataLoading: false,

  chartDetailData: null,
  chartDetailLoading: false,
  chartDetailError: null,

  creatingChart: false,
  creatingChartError: null,

  savingExistingChart: false,
  updatingChartError: null,

  deletingChart: false,
  deletingChartError: null,

  shareableUsers: [],
  shareableUsersLoading: false,
  shareableUsersError: null,

  sharing: false,
  sharingError: null,
};

//--- [2] Action Types ---
const prefix = 'app/chartBuilder/';
export const types = {
  //--- SCOPE ---
  CLEAR_SITES: prefix + 'CLEAR_SITES',
  SITES_REQUEST: prefix + 'SITES_REQUEST',
  SITES_RESPONSE: prefix + 'SITES_RESPONSE',

  //--- BUILDINGS ---
  CLEAR_BUILDINGS: prefix + 'CLEAR_BUILDINGS',
  BUILDINGS_REQUEST: prefix + 'BUILDINGS_REQUEST',
  BUILDINGS_RESPONSE: prefix + 'BUILDINGS_RESPONSE',

  //--- EQUIP CLASSES ---
  CLEAR_EQUIP_CLASSES: prefix + 'CLEAR_EQUIP_CLASSES',
  EQUIP_CLASSES_REQUEST: prefix + 'EQUIP_CLASSES_REQUEST',
  EQUIP_CLASSES_RESPONSE: prefix + 'EQUIP_CLASSES_RESPONSE',

  //--- EQUIPMENT ---
  CLEAR_EQUIPMENT: prefix + 'CLEAR_EQUIPMENT',
  EQUIPMENT_REQUEST: prefix + 'EQUIPMENT_REQUEST',
  EQUIPMENT_RESPONSE: prefix + 'EQUIPMENT_RESPONSE',

  //--- POINT CLASSES ---
  CLEAR_POINT_CLASSES: prefix + 'CLEAR_POINT_CLASSES',
  POINT_CLASSES_REQUEST: prefix + 'POINT_CLASSES_REQUEST',
  POINT_CLASSES_RESPONSE: prefix + 'POINT_CLASSES_RESPONSE',

  //--- POINT COUNT ---
  CLEAR_POINT_COUNT: prefix + 'CLEAR_POINT_COUNT',
  POINT_COUNT_REQUEST: prefix + 'POINT_COUNT_REQUEST',
  POINT_COUNT_RESPONSE: prefix + 'POINT_COUNT_RESPONSE',

  //--- POINTS ---
  CLEAR_POINTS: prefix + 'CLEAR_POINTS',
  POINTS_REQUEST: prefix + 'POINTS_REQUEST',
  POINTS_RESPONSE: prefix + 'POINTS_RESPONSE',

  //-- Full chart filters ---
  FULL_CHART_FILTERS_REQUEST: prefix + 'FULL_CHART_FILTERS_REQUEST',
  FULL_CHART_FILTERS_RESPONSE: prefix + 'FULL_CHART_FILTERS_RESPONSE',

  //--- TREND DATA ---
  CLEAR_TREND_DATA: prefix + 'CLEAR_TREND_DATA',
  TREND_DATA_REQUEST: prefix + 'TREND_DATA_REQUEST',
  TREND_DATA_RESPONSE: prefix + 'TREND_DATA_RESPONSE',
  CLEAR_CONTEXT_DATA: prefix + 'CLEAR_CONTEXT_DATA',
  CONTEXT_DATA_REQUEST: prefix + 'CONTEXT_DATA_REQUEST',
  CONTEXT_DATA_RESPONSE: prefix + 'CONTEXT_DATA_RESPONSE',
  CLEAR_CSV_DATA: prefix + 'CLEAR_CSV_DATA',
  CSV_DATA_REQUEST: prefix + 'CSV_DATA_REQUEST',
  CSV_DATA_RESPONSE: prefix + 'CSV_DATA_RESPONSE',

  //--- SAVED INPUTS ---
  CLEAR_SAVED_INPUTS: prefix + 'CLEAR_SAVED_INPUTS',
  SAVED_INPUTS_REQUEST: prefix + 'SAVED_INPUTS_REQUEST',
  SAVED_INPUTS_RESPONSE: prefix + 'SAVED_INPUTS_RESPONSE',

  UPDATE_SAVED_INPUTS_REQUEST: prefix + 'UPDATE_SAVED_INPUTS_REQUEST',
  UPDATE_SAVED_INPUTS_RESPONSE: prefix + 'UPDATE_SAVED_INPUTS_RESPONSE',

  CREATE_SAVED_INPUTS_REQUEST: prefix + 'CREATE_SAVED_INPUTS_REQUEST',
  CREATE_SAVED_INPUTS_RESPONSE: prefix + 'CREATE_SAVED_INPUTS_RESPONSE',

  DELETE_SAVED_INPUTS_REQUEST: prefix + 'DELETE_SAVED_INPUTS_REQUEST',
  DELETE_SAVED_INPUTS_RESPONSE: prefix + 'DELETE_SAVED_INPUTS_RESPONSE',

  LIBRARY_DATA_REQUEST: prefix + 'LIBRARY_DATA_REQUEST',
  LIBRARY_DATA_RESPONSE: prefix + 'LIBRARY_DATA_RESPONSE',

  CHART_DETAIL_REQUEST: prefix + 'CHART_DETAIL_REQUEST',
  CHART_DETAIL_RESPONSE: prefix + 'CHART_DETAIL_RESPONSE',
  CLEAR_CHART_DETAIL: prefix + 'CLEAR_CHART_DETAIL',

  CREATE_CHART_REQUEST: prefix + 'CREATE_CHART_REQUEST',
  CREATE_CHART_RESPONSE: prefix + 'CREATE_CHART_RESPONSE',
  UPDATE_CHART_REQUEST: prefix + 'UPDATE_CHART_REQUEST',
  UPDATE_CHART_RESPONSE: prefix + 'UPDATE_CHART_RESPONSE',
  DELETE_CHART_REQUEST: prefix + 'DELETE_CHART_REQUEST',
  DELETE_CHART_RESPONSE: prefix + 'DELETE_CHART_RESPONSE',

  SHAREABLE_USERS_REQUEST: prefix + 'SHAREABLE_USERS_REQUEST',
  SHAREABLE_USERS_RESPONSE: prefix + 'SHAREABLE_USERS_RESPONSE',

  SHARE_CHART_REQUEST: prefix + 'SHARE_CHART_REQUEST',
  SHARE_CHART_RESPONSE: prefix + 'SHARE_CHART_RESPONSE',
};

//--- [3] Action Creators ---
export const actions = {
  //--- SITES ---
  clearSites: createAction(types.CLEAR_SITES),
  sitesRequest: createAction(types.SITES_REQUEST),
  sitesResponse: createAction(types.SITES_RESPONSE),

  //--- BUILDINGS ---
  clearBuildings: createAction(types.CLEAR_BUILDINGS),
  buildingsRequest: createAction(types.BUILDINGS_REQUEST),
  buildingsResponse: createAction(types.BUILDINGS_RESPONSE),

  //--- EQUIP CLASSES ---
  clearEquipClasses: createAction(types.CLEAR_EQUIP_CLASSES),
  equipClassesRequest: createAction(types.EQUIP_CLASSES_REQUEST),
  equipClassesResponse: createAction(types.EQUIP_CLASSES_RESPONSE),

  //--- EQUIPMENT ---
  clearEquipment: createAction(types.CLEAR_EQUIPMENT),
  equipmentRequest: createAction(types.EQUIPMENT_REQUEST),
  equipmentResponse: createAction(types.EQUIPMENT_RESPONSE),

  //--- POINT CLASSES ---
  clearPointClasses: createAction(types.CLEAR_POINT_CLASSES),
  pointClassesRequest: createAction(types.POINT_CLASSES_REQUEST),
  pointClassesResponse: createAction(types.POINT_CLASSES_RESPONSE),

  //--- POINT COUNT ---
  clearPointCount: createAction(types.CLEAR_POINT_COUNT),
  pointCountRequest: createAction(types.POINT_COUNT_REQUEST),
  pointCountResponse: createAction(types.POINT_COUNT_RESPONSE),

  //--- POINTS ---
  clearPoints: createAction(types.CLEAR_POINTS),
  pointsRequest: createAction(types.POINTS_REQUEST),
  pointsResponse: createAction(types.POINTS_RESPONSE),

  //-- Full chart filters ---
  fullChartFiltersRequest: createAction(types.FULL_CHART_FILTERS_REQUEST),
  fullChartFiltersResponse: createAction(types.FULL_CHART_FILTERS_RESPONSE),

  //--- TREND DATA ---
  clearTrendData: createAction(types.CLEAR_TREND_DATA),
  trendDataRequest: createAction(types.TREND_DATA_REQUEST),
  trendDataResponse: createAction(types.TREND_DATA_RESPONSE),
  clearContextData: createAction(types.CLEAR_CONTEXT_DATA),
  contextDataRequest: createAction(types.CONTEXT_DATA_REQUEST),
  contextDataResponse: createAction(types.CONTEXT_DATA_RESPONSE),
  clearCSVData: createAction(types.CLEAR_CSV_DATA),
  csvDataRequest: createAction(types.CSV_DATA_REQUEST),
  csvDataResponse: createAction(types.CSV_DATA_RESPONSE),

  //--- SAVED INPUTS ---
  clearSavedInputs: createAction(types.CLEAR_SAVED_INPUTS),
  savedInputsRequest: createAction(types.SAVED_INPUTS_REQUEST),
  savedInputsResponse: createAction(types.SAVED_INPUTS_RESPONSE),
  createSavedInputsRequest: createAction(types.CREATE_SAVED_INPUTS_REQUEST),
  createSavedInputsResponse: createAction(types.CREATE_SAVED_INPUTS_RESPONSE),
  updateSavedInputsRequest: createAction(types.UPDATE_SAVED_INPUTS_REQUEST),
  updateSavedInputsResponse: createAction(types.UPDATE_SAVED_INPUTS_RESPONSE),
  deleteSavedInputsRequest: createAction(types.DELETE_SAVED_INPUTS_REQUEST),
  deleteSavedInputsResponse: createAction(types.DELETE_SAVED_INPUTS_RESPONSE),

  //LIBRARY
  libraryDataRequest: createAction(types.LIBRARY_DATA_REQUEST),
  libraryDataResponse: createAction(types.LIBRARY_DATA_RESPONSE),

  //chartDetail
  chartDetailRequest: createAction(types.CHART_DETAIL_REQUEST),
  chartDetailResponse: createAction(types.CHART_DETAIL_RESPONSE),

  clearChartDetail: createAction(types.CLEAR_CHART_DETAIL),

  createChartRequest: createAction(types.CREATE_CHART_REQUEST),
  createChartResponse: createAction(types.CREATE_CHART_RESPONSE),
  updateChartRequest: createAction(types.UPDATE_CHART_REQUEST),
  updateChartResponse: createAction(types.UPDATE_CHART_RESPONSE),
  deleteChartRequest: createAction(types.DELETE_CHART_REQUEST),
  deleteChartResponse: createAction(types.DELETE_CHART_RESPONSE),

  shareableUsersRequest: createAction(types.SHAREABLE_USERS_REQUEST),
  shareableUsersResponse: createAction(types.SHAREABLE_USERS_RESPONSE),

  shareChartRequest: createAction(types.SHARE_CHART_REQUEST),
  shareChartResponse: createAction(types.SHARE_CHART_RESPONSE),

  //
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - -  [4] API CALLS  - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

// - - -  SITES - - -
export const fetchSites = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.sitesRequest());
  try {
    const { data } = await api.chartBuilder.getSites();
    return dispatch(actions.sitesResponse(data.data));
  } catch (err) {
    return dispatch(actions.sitesResponse(err));
  }
};

export const clearSites = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearSites());
};

// - - -  BUILDINGS - - -
const fetchBuildings_raw = siteId => async (dispatch, _getState, { api }) => {
  dispatch(actions.buildingsRequest());
  try {
    const { data } = await api.chartBuilder.getBuildings(siteId);
    return dispatch(actions.buildingsResponse(data.data));
  } catch (err) {
    return dispatch(actions.buildingsResponse(err));
  }
};
export const fetchBuildings = memoizeOne(fetchBuildings_raw);

export const clearBuildings = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearBuildings());
};

const fetchChartBuilderFilters_raw = (siteId, pointIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fullChartFiltersRequest())
  try {
    const [pointResult, pointClassResult, equipResult, equipClassResult] =
      await Promise.all([
        api.chartBuilder.getPoints(siteId, [], [], [], [], pointIds, '', false),
        api.chartBuilder.getPointClasses(siteId, [], [], []),
        api.chartBuilder.getEquipment(siteId, [], []),
        api.chartBuilder.getEquipClasses(siteId, [])
      ]);
    return dispatch(
      actions.fullChartFiltersResponse(
        {
          points: pointResult,
          pointClasses: pointClassResult,
          equipment: equipResult,
          equipClasses: equipClassResult
        }
      )
    );
  } catch (err) {
    return dispatch(actions.fullChartFiltersResponse(err));
  }
};
export const fetchChartBuilderFilters = memoizeOne(fetchChartBuilderFilters_raw);

export const setChartBuilderFilters = (points, pointClasses, equipment, equipClasses) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.fullChartFiltersRequest())
  try {
    const data = {
      points: {
        data: points,
      },
      pointClasses: {
        data: pointClasses,
      },
      equipClasses: {
        data: equipClasses,
      },
      equipment: {
        data: equipment,
      }
    }
    return dispatch(
      actions.fullChartFiltersResponse(
        {
          points: data.points,
          pointClasses: data.pointClasses,
          equipment: data.equipment,
          equipClasses: data.equipment,
        }
      )
    );
  } catch (err) {
    return dispatch(actions.fullChartFiltersResponse(err));
  }
};


// - - -  EQUIP CLASSES - - -
const fetchEquipClasses_raw = (siteId, buildingIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.equipClassesRequest());
  try {
    const { data } = await api.chartBuilder.getEquipClasses(
      siteId,
      buildingIds
    );
    return dispatch(actions.equipClassesResponse(data.data));
  } catch (err) {
    return dispatch(actions.equipClassesResponse(err));
  }
};
export const fetchEquipClasses = memoizeOne(fetchEquipClasses_raw);

export const clearEquipClasses = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearEquipClasses());
};

// - - -  EQUIPMENT - - -
const fetchEquipment_raw = (siteId, buildingIds, equipClassIds) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.equipmentRequest());
  try {
    const { data } = await api.chartBuilder.getEquipment(
      siteId,
      buildingIds,
      equipClassIds
    );
    return dispatch(actions.equipmentResponse(data.data));
  } catch (err) {
    return dispatch(actions.equipmentResponse(err));
  }
};
export const fetchEquipment = memoizeOne(fetchEquipment_raw);

export const clearEquipment = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearEquipment());
};

// - - -  POINT CLASSES - - -
const fetchPointClasses_raw = (
  siteId,
  buildingIds,
  equipClassIds,
  equipIds
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.pointClassesRequest());
  try {
    const { data } = await api.chartBuilder.getPointClasses(
      siteId,
      buildingIds,
      equipClassIds,
      equipIds
    );
    return dispatch(actions.pointClassesResponse(data.data));
  } catch (err) {
    return dispatch(actions.pointClassesResponse(err));
  }
};
export const fetchPointClasses = memoizeOne(fetchPointClasses_raw);

export const clearPointClasses = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearPointClasses());
};

// - - -  POINT COUNT - - -
const fetchPointCount_raw = (
  siteId,
  buildingIds,
  equipClassIds,
  equipIds,
  pointClassIds,
  search,
  isRaw
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.pointCountRequest());
  try {
    const { data } = await api.chartBuilder.getPointCount(
      siteId,
      buildingIds,
      equipClassIds,
      equipIds,
      pointClassIds,
      search,
      isRaw
    );
    return dispatch(actions.pointCountResponse(data.data));
  } catch (err) {
    return dispatch(actions.pointCountResponse(err));
  }
};
export const fetchPointCount = memoizeOne(fetchPointCount_raw);

export const clearPointCount = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearPointCount());
};

// - - -  POINTS - - -
const fetchPoints_raw = (
  siteId,
  buildingIds,
  equipClassIds,
  equipIds,
  pointClassIds,
  pointIds,
  search,
  isRaw
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.pointsRequest());
  try {
    const { data } = await api.chartBuilder.getPoints(
      siteId,
      buildingIds,
      equipClassIds,
      equipIds,
      pointClassIds,
      pointIds,
      search,
      isRaw
    );
    return dispatch(actions.pointsResponse(data.data));
  } catch (err) {
    return dispatch(actions.pointsResponse(err));
  }
};
export const fetchPoints = memoizeOne(fetchPoints_raw);

export const clearPoints = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearPoints());
};

// - - -  TREND DATA - - -
export const fetchTrendData = (
  configId,
  pointIds,
  startDate,
  endDate,
  timezone,
  interval = 1,
  intervalUnit = 'day',
  stat = 'average'
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.trendDataRequest());
  try {
    const { data } = await api.chartBuilder.getTrendData(
      configId,
      pointIds,
      startDate,
      endDate,
      interval,
      intervalUnit,
      stat,
      timezone
    );
    return dispatch(actions.trendDataResponse(data));
  } catch (err) {
    return dispatch(actions.trendDataResponse(err));
  }
};

export const clearTrendData = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearTrendData());
};

export const fetchContextData = (
  configId,
  pointIds,
  startDate,
  endDate,
  timezone,
  interval = 1,
  intervalUnit = 'day'
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.contextDataRequest());
  try {
    const { data } = await api.chartBuilder.getTrendData(
      configId,
      pointIds,
      startDate,
      endDate,
      interval,
      intervalUnit,
      'average',
      timezone
    );
    return dispatch(actions.contextDataResponse(data));
  } catch (err) {
    return dispatch(actions.contextDataResponse(err));
  }
};

export const clearContextData = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearContextData());
};

/**
 *
 * Creates and returns a csv string to be downloaded
 *
 * @param {Array[Object]} trendData array of objects representing trend data set
 * @param {boolean} isRawPointNames bool to use raw or display point names
 * @param {Array[Object]} selectedPoints array of selected point objects
 * @param {number} scatterXaxis (nullable) point id representing scatter plot X-axis
 * @param {number} scatterYaxis (nullable) point id representing scatter plot Y-axis
 * @param {number} scatterColorVar (nullable) point id representing scatter plot color var
 * @param {number} scatterSizeVar (nullable) point id representing scatter plot color var
 */
const _createTrendCsvString = (
  trendData,
  isRawPointNames,
  selectedPoints,
  startDate,
  endDate,
  interval,
  intervalUnit,
  scatterXaxis = null,
  scatterYaxis = null,
  scatterColorVar = null,
  scatterSizeVar = null,
) => {
  //throw error if no data to download
  if (!trendData.length) {
    throw new Error('Must have at least one data point to download');
  }
  //throw error if no points selected
  if (!selectedPoints.length) {
    throw new Error('Please select at least one point');
  }
  //Throw error if two scatter axises not selected
  if (
    (scatterXaxis = null && !(scatterYaxis == null)) ||
    (scatterYaxis == null && !(scatterXaxis == null))
  ) {
    throw new Error(
      'Please select both an X and Y axis point for scatter data'
    );
  }

  //need to fill contiguous intervals for start + end date
  //start and end dates for request
  let start = moment(startDate)
  let end = moment(endDate)
  var nullPointKV = {}
  //create a null key for each point selected in request
  selectedPoints.forEach(x => nullPointKV[`${x.id}`] = null);
  //will hold all of our created intervals
  var intervalsTs = {}
  //create a new key for each
  while (start.toISOString() <= end.toISOString()) {
    //match string to what comes back from ts
    let currentTsString = start.format('YYYY-MM-DD HH:mm:ss')
    // map null to interval as key
    intervalsTs[`${currentTsString}`] = { ts: currentTsString, ...nullPointKV }
    // increment current time by our requested interval/unit
    start.add(interval, intervalUnit.charAt(0))
  }
  //trend data from global state in format {`pointid`: pointvalue, ..., ts: timestampstring}
  //insert to empty interval key for each trendvalue, will insert new k/v for trend not on interval (cov points)
  trendData.forEach(x => {
    intervalsTs[`${x.ts}`] = x
  })

  // sort by ts value, unfortunate that we need to do this but we can't rely on the ordering of the key's insertion
  let data = Object.values(intervalsTs).sort((a, b) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0))

  // intialize arrays for data and header keys
  let dataAsArray = [];
  let keyarray = [];
  //if scatter x and y passed use them as keys
  if (scatterXaxis && scatterYaxis) {
    //base header key array
    keyarray = ['ts', 'X-Axis', 'Y-Axis'];
    //key values for ts and point key value pairs
    let valueKeys = ['ts', `${scatterXaxis}`, `${scatterYaxis}`];
    //if scatter or color optionally passed add header and value keys to respective lists
    if (scatterColorVar) {
      keyarray.push('Color');
      valueKeys.push(`${scatterColorVar}`);
    }
    if (scatterSizeVar) {
      keyarray.push('Size');
      valueKeys.push(`${scatterSizeVar}`);
    }
    //push header as first row in resultant array
    dataAsArray.push(keyarray);
    //for each data record...
    data.forEach(record => {
      //create a list
      let valuesArray = [];
      //push value for each key in order to array for row in csv
      valueKeys.forEach(valueKey => {
        valuesArray.push(record[valueKey]);
      });
      //append inner array of values for record to total list
      dataAsArray.push(valuesArray);
    });
  } else {
    //create key array from 'ts' at front with selected point ids after
    keyarray = ['ts', ...selectedPoints.map(p => p.id)];

    let headerArray = [keyarray[0]];
    for (let index = 1; index < keyarray.length; index++) {
      let pointIdAtKeyIndex = keyarray[index];
      let pointObjectForKey = selectedPoints.find(
        point => point.id === pointIdAtKeyIndex
      );
      let pointNameForPointIdKey = `${keyarray[index]}`;
      if (pointObjectForKey) {
        pointNameForPointIdKey = isRawPointNames
          ? pointObjectForKey.PointName
          : pointObjectForKey.DisplayName;
      }
      headerArray.push(pointNameForPointIdKey);
    }

    //push shifted keys array as header row in resultant list
    dataAsArray.push(headerArray);
    //for each data record ...
    data.forEach(record => {
      //create array of values
      let valuesArray = [];
      keyarray.forEach(valueKey => {
        valuesArray.push(record[`${valueKey}`]);
      });
      //push shifted values array to final data array
      dataAsArray.push(valuesArray);
    });
  }
  //csv contentstring
  let csvContent = '';
  //for each row in result list
  dataAsArray.forEach(function (rowArray) {
    //delimit with comma
    let row = rowArray.join(',');
    //move cursor
    csvContent += row + '\r\n';
  });
  //return final csv string
  return csvContent;
};

export const fetchCSVData = (
  trendData,
  isRawPointNames,
  selectedPoints,
  startDate,
  endDate,
  interval,
  intervalUnit,
  chartName = null,
  scatterXaxis = null,
  scatterYaxis = null,
  scatterColorVar = null,
  scatterSizeVar = null
) => async (dispatch, _getState, { api }) => {
  dispatch(actions.csvDataRequest());
  try {
    const csvDataArray = _createTrendCsvString(
      trendData,
      isRawPointNames,
      selectedPoints,
      startDate,
      endDate,
      interval,
      intervalUnit,
      scatterXaxis,
      scatterYaxis,
      scatterColorVar,
      scatterSizeVar
    );
    //replace any spaces in chart name with underscores
    let csvFilename = '';
    if (chartName) {
      let formattedChartName = chartName.replace(' ', '_');
      csvFilename = formattedChartName + '_CSVData.csv';
    } else {
      csvFilename = 'Untitled_CSVData.csv';
    }
    //download csv using download js to avoid browser blocking filesize and using chart name as filetitle
    download(csvDataArray, csvFilename, 'data:text/csv;charset=utf-8');
    return dispatch(actions.csvDataResponse(csvDataArray));
  } catch (err) {
    return dispatch(actions.csvDataResponse(err));
  }
};

export const clearCSVData = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearCSVData());
};

// - - - SAVED INPUTS - - -
export const fetchSavedInputs = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.savedInputsRequest());
  try {
    const { data } = await api.chartBuilder.getSavedInputs();
    return dispatch(actions.savedInputsResponse(data.data));
  } catch (err) {
    return dispatch(actions.savedInputsResponse(err));
  }
};

export const clearSavedInputs = () => async (dispatch, _getState, { api }) => {
  dispatch(actions.clearSavedInputs());
};

export const createSavedInputs = _inputs => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.createSavedInputsRequest());
  try {
    await api.chartBuilder.postSavedInputs(_inputs);
    const { data } = await api.chartBuilder.getSavedInputs();
    return dispatch(actions.createSavedInputsResponse(data.data));
  } catch (err) {
    return dispatch(actions.createSavedInputsResponse(err));
  }
};

export const updateSavedInputs = (_id, _inputs) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateSavedInputsRequest());
  try {
    await api.chartBuilder.putSavedInputs(_id, _inputs);
    const { data } = await api.chartBuilder.getSavedInputs();
    return dispatch(actions.updateSavedInputsResponse(data.data));
  } catch (err) {
    return dispatch(actions.updateSavedInputsResponse(err));
  }
};

export const deleteSavedInputs = _id => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.deleteSavedInputsRequest());
  try {
    await api.chartBuilder.deleteSavedInputs(_id);
    const { data } = await api.chartBuilder.getSavedInputs();
    return dispatch(actions.deleteSavedInputsResponse(data.data));
  } catch (err) {
    return dispatch(actions.deleteSavedInputsResponse(err));
  }
};

// LIBRARY
export const fetchLibraryData = (userId, configId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.libraryDataRequest());
  try {
    const { data } = await api.chartBuilder.getLibraryData(userId, configId);
    return dispatch(actions.libraryDataResponse(data));
  } catch (err) {
    return dispatch(actions.libraryDataResponse(err));
  }
};

export const fetchChartDetail = chartId => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.chartDetailRequest());
  try {
    const { data } = await api.chartBuilder.getChartDetail(chartId);
    return dispatch(actions.chartDetailResponse(data));
  } catch (err) {
    return dispatch(actions.chartDetailResponse(err));
  }
};

export const clearChartDetail = () => async (dispatch, _getState) => {
  dispatch(actions.clearChartDetail());
};

export const createChart = chart => async (dispatch, _getState, { api }) => {
  dispatch(actions.createChartRequest());
  try {
    await api.chartBuilder.postChart(chart);
    //should I get the new chart after post?
    const { data } = await api.chartBuilder.getLibraryData(
      chart.createdUserId,
      chart.configId
    );
    return dispatch(actions.createChartResponse(data));
  } catch (err) {
    return dispatch(actions.createChartResponse(err));
  }
};

export const updateChart = (chartId, chartData) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.updateChartRequest());
  try {
    await api.chartBuilder.putChart(chartId, chartData);
    const { data } = await api.chartBuilder.getChartDetail(chartId);
    return dispatch(actions.updateChartResponse(data));
  } catch (err) {
    return dispatch(actions.updateChartResponse(err));
  }
};

export const deleteChart = (chartId, userId, configId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.deleteChartRequest());
  try {
    await api.chartBuilder.deleteChart(chartId);
    const { data } = await api.chartBuilder.getLibraryData(userId, configId);
    //should I get the new chart after post?
    //const { data } = await api.chartBuilder.getChartDetail();
    return dispatch(actions.deleteChartResponse(data));
  } catch (err) {
    return dispatch(actions.deleteChartResponse(err));
  }
};

export const fetchShareableUsers = (chartId, configId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.shareableUsersRequest());
  try {
    const { data } = await api.chartBuilder.getShareableUsers(
      chartId,
      configId
    );
    return dispatch(actions.shareableUsersResponse(data));
  } catch (err) {
    return dispatch(actions.shareableUsersResponse(err));
  }
};

export const shareChart = (chartId, userIds, configId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch(actions.shareChartRequest());
  try {
    await api.chartBuilder.postSharedUsers(chartId, userIds);
    const { data } = await api.chartBuilder.getShareableUsers(
      chartId,
      configId
    );
    return dispatch(actions.shareChartResponse(data));
  } catch (err) {
    return dispatch(actions.shareChartResponse(err));
  }
};

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [5] DATA MANIPULATION - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - - - - - - - - -
// - - - [6] ACTIONS - - - - - - - - - - - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - -
export default handleActions(
  {
    //--- SITES ---
    [actions.sitesRequest]: {
      next: state => ({
        ...state,
        siteLoading: true,
      }),
    },
    [actions.sitesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        sites: payload,
        siteLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        siteError: payload.message,
        siteLoading: false,
      }),
    },
    [actions.clearSites]: {
      next: state => ({
        ...state,
        sites: [],
      }),
    },
    //--- BUILDINGS ---
    [actions.buildingsRequest]: {
      next: state => ({
        ...state,
        buildingLoading: true,
      }),
    },
    [actions.buildingsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        buildings: payload,
        buildingLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        buildingError: payload.message,
        buildingLoading: false,
      }),
    },
    [actions.clearBuildings]: {
      next: state => ({
        ...state,
        buildings: [],
      }),
    },
    //--- EQUIP CLASSES ---
    [actions.equipClassesRequest]: {
      next: state => ({
        ...state,
        equipClassLoading: true,
      }),
    },
    [actions.equipClassesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipClasses: payload,
        equipClassLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipClassError: payload.message,
        equipClassLoading: false,
      }),
    },
    [actions.clearEquipClasses]: {
      next: state => ({
        ...state,
        equipClasses: [],
      }),
    },
    //--- EQUIPMENT ---
    [actions.equipmentRequest]: {
      next: state => ({
        ...state,
        equipmentLoading: true,
      }),
    },
    [actions.equipmentResponse]: {
      next: (state, { payload }) => ({
        ...state,
        equipment: payload,
        equipmentLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        equipmentError: payload.message,
        equipmentLoading: false,
      }),
    },
    [actions.clearEquipment]: {
      next: state => ({
        ...state,
        equipment: [],
      }),
    },
    //--- POINT CLASSES ---
    [actions.pointClassesRequest]: {
      next: state => ({
        ...state,
        pointClassLoading: true,
      }),
    },
    [actions.pointClassesResponse]: {
      next: (state, { payload }) => ({
        ...state,
        pointClasses: payload,
        pointClassLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        pointClassError: payload.message,
        pointClassLoading: false,
      }),
    },
    [actions.clearPointClasses]: {
      next: state => ({
        ...state,
        pointClasses: [],
      }),
    },
    //--- POINT COUNT ---
    [actions.pointCountRequest]: {
      next: state => ({
        ...state,
        pointLoading: true,
      }),
    },
    [actions.pointCountResponse]: {
      next: (state, { payload }) => ({
        ...state,
        pointCount: payload,
        pointLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        pointCountError: payload.message,
        pointLoading: false,
      }),
    },
    [actions.clearPointCount]: {
      next: state => ({
        ...state,
        pointCount: null,
      }),
    },
    //--- POINTS ---
    [actions.pointsRequest]: {
      next: state => ({
        ...state,
        pointLoading: true,
      }),
    },
    [actions.pointsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        points: payload ? alphaSortObjectArray(payload, 'PointName') : [],
        pointLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        pointError: payload.message,
        pointLoading: false,
      }),
    },
    [actions.clearPoints]: {
      next: state => ({
        ...state,
        points: [],
      }),
    },
    [actions.fullChartFiltersRequest]: {
      next: state => ({
        ...state,
        loadingFromURL: true,
      }),
    },
    [actions.fullChartFiltersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        points: payload?.points?.data ? alphaSortObjectArray(payload.points.data, 'PointName') : [],
        pointClasses: payload?.pointClasses?.data,
        equipment: payload?.equipment?.data,
        equipClasses: payload?.equipClasses?.data,
        loadingFromURL: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        pointError: payload.message,
        pointClassError: payload.message,
        equipmentError: payload.message,
        equipClassError: payload.message,
        loadingFromURL: false,
      }),
    },
    //--- TREND DATA ---
    [actions.trendDataRequest]: {
      next: state => ({
        ...state,
        trendDataLoading: true,
      }),
    },
    [actions.trendDataResponse]: {
      next: (state, { payload }) => {
        let pointIdsWithData = new Set();
        if (payload?.data?.length > 0) {
          payload.data.forEach(trendObj => {
            Object.keys(trendObj).forEach(key => {
              if (key !== 'ts') {
                pointIdsWithData.add(key);
              }
            });
          })
        }

        return ({
          ...state,
          trendDataPointIdsWithData: pointIdsWithData,
          trendData: payload.data,
          trendDataLoading: false,
          pointUnits: payload.pointUnits
        })
      },

      throw: (state, { payload }) => ({
        ...state,
        trendDataError: payload.message,
        trendDataLoading: false,
      }),
    },
    [actions.clearTrendData]: {
      next: state => ({
        ...state,
        trendData: [],
      }),
    },
    [actions.contextDataRequest]: {
      next: state => ({
        ...state,
        contextDataLoading: true,
      }),
    },
    [actions.contextDataResponse]: {
      next: (state, { payload }) => ({
        ...state,
        contextData: payload.data,
        contextDataLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        contextDataError: payload.message,
        contextDataLoading: false,
      }),
    },
    [actions.clearContextData]: {
      next: state => ({
        ...state,
        contextData: [],
      }),
    },
    //--- SAVED INPUTS ---
    [actions.savedInputsRequest]: {
      next: state => ({
        ...state,
        savedInputsLoading: true,
      }),
    },
    [actions.savedInputsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        savedInputs: payload,
        savedInputsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        savedInputsError: payload.message,
        savedInputsLoading: false,
      }),
    },
    [actions.createSavedInputsRequest]: {
      next: state => ({
        ...state,
        savedInputsLoading: true,
      }),
    },
    [actions.createSavedInputsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        savedInputs: payload,
        savedInputsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        savedInputsError: payload.message,
        savedInputsLoading: false,
      }),
    },
    [actions.updateSavedInputsRequest]: {
      next: state => ({
        ...state,
        savedInputsLoading: true,
      }),
    },
    [actions.updateSavedInputsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        savedInputs: payload,
        savedInputsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        savedInputsError: payload.message,
        savedInputsLoading: false,
      }),
    },
    [actions.deleteSavedInputsRequest]: {
      next: state => ({
        ...state,
        savedInputsLoading: true,
      }),
    },
    [actions.deleteSavedInputsResponse]: {
      next: (state, { payload }) => ({
        ...state,
        savedInputs: payload,
        savedInputsLoading: false,
      }),
      throw: (state, { payload }) => ({
        ...state,
        savedInputsError: payload.message,
        savedInputsLoading: false,
      }),
    },
    [actions.libraryDataRequest]: {
      next: state => ({
        ...state,
        libraryDataLoading: true,
      }),
    },
    [actions.libraryDataResponse]: {
      next: (state, { payload }) => ({
        ...state,
        libraryData: payload,
        libraryDataLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        libraryDataError: payload.message,
        libraryDataLoading: false,
      }),
    },
    [actions.chartDetailRequest]: {
      next: state => ({
        ...state,
        chartDetailLoading: true,
      }),
    },
    [actions.chartDetailResponse]: {
      next: (state, { payload }) => ({
        ...state,
        chartDetailData: payload,
        chartDetailLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        chartDetailError: payload.message,
        chartDetailLoading: false,
      }),
    },
    [actions.clearChartDetail]: {
      next: state => ({
        ...state,
        chartDetailData: null,
      }),
    },
    [actions.createChartRequest]: {
      next: state => ({
        ...state,
        creatingChart: true,
        libraryDataLoading: true,
      }),
    },
    [actions.createChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        creatingChart: false,
        libraryDataLoading: false,
        libraryData: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        creatingChartError: payload.message,
        libraryDataLoading: false,
        creatingChart: false,
      }),
    },
    [actions.updateChartRequest]: {
      next: state => ({
        ...state,
        savingExistingChart: true,
      }),
    },
    [actions.updateChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        chartDetailData: payload,
        savingExistingChart: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        updatingChartError: payload.message,
        savingExistingChart: false,
      }),
    },
    [actions.deleteChartRequest]: {
      next: state => ({
        ...state,
        deletingChart: true,
      }),
    },
    [actions.deleteChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        deletingChart: false,
        libraryData: payload,
      }),

      throw: (state, { payload }) => ({
        ...state,
        deletingChartError: payload.message,
        deletingChart: false,
      }),
    },
    [actions.shareableUsersRequest]: {
      next: state => ({
        ...state,
        shareableUsersLoading: true,
      }),
    },
    [actions.shareableUsersResponse]: {
      next: (state, { payload }) => ({
        ...state,
        shareableUsers: payload,
        shareableUsersLoading: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        shareableUsersError: payload.message,
        shareableUsersLoading: false,
      }),
    },
    [actions.shareChartRequest]: {
      next: state => ({
        ...state,
        sharing: true,
      }),
    },
    [actions.shareChartResponse]: {
      next: (state, { payload }) => ({
        ...state,
        shareableUsers: payload,
        sharing: false,
      }),

      throw: (state, { payload }) => ({
        ...state,
        sharingError: payload.message,
        sharing: false,
      }),
    },
    [actions.csvDataRequest]: {
      next: state => ({
        ...state,
        csvDownloadLoading: true,
        csvDownloadError: null,
      }),
    },
    [actions.csvDataResponse]: {
      next: (state, { payload }) => ({
        ...state,
        csvDownloadLoading: false,
        csvDownloadError: null,
      }),

      throw: (state, { payload }) => ({
        ...state,
        csvDownloadError: payload.message,
        csvDownloadLoading: false,
      }),
    },
  },
  initialState
);
